import './registerServiceWorker'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import { FirebaseAuth } from '@smartapp/auth-lib'
import firebase from 'firebase/compat/app'

//Configuration firebase :
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  application: process.env.VUE_APP_APP_NAME,
  country: process.env.VUE_APP_COUNTRY
}

const app = firebase.initializeApp(config)

new FirebaseAuth(config, app, ['development', 'staging'].includes(process.env.VUE_APP_BUILD_ENV))
  .signIn()
  .then(fAuth =>
    fAuth.authorization().catch(authorizationError => {
      //error authorization
      console.warn('Error on authorization', authorizationError)
      window.location.href = '/forbidden.html'
    })
  )
  .then(({ firebaseUser, user, roles }) => {
    import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
      createApp.default({ firebaseUser, user, roles })
    })
  })
  .catch(signInError => {
    //catch signIng
    console.warn('Error on authentification', signInError)
    window.location.href = '/error.html'
  })
